
import request from "@/utils/request";


export function certificateQuery(param) {
    return request({
        url: '/dev-api/evidence/certificateQuery?certificateNo='+param.certificateNo + "&hashValue=" + param.hashValue,
        headers: {
            isToken: false
        },
        method: 'get',

    })
}
export function  updateEvidence(data){
    return request({
        url:'/dev-api/evidence/',
        method:'PUT',
        data :data
    })
};

export  function  download(id){
    return request({
        url:'/dev-api/evidence/download/'+id,
        method:'get'
    })
}
