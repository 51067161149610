//
// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import HomeView from '../views/HomeViewcopy.vue'
// import Layout from '../views/Layout.vue'
//
// Vue.use(VueRouter)
//
// const routes = [
//   {
//     path:'',
//     name:'layout',
//     component:Layout,
//     children:[
//       {
//         path: '/',
//         name: 'home',
//         component: HomeView
//       },
//       {
//         path: '/about',
//         name: 'about',
//         component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//       }
//     ]
//   },
//
//
//
// ]
//
// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })
//
// export default router



import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Layout from '../views/Layout.vue'
import Login from '@/views/login.vue'; // 引入login.vue组件
import Register from '@/views/register.vue';
import changePassword from "@/views/changePassword.vue";
import InformationAdmin from "@/views/InformationAdmin.vue";

Vue.use(VueRouter)

const routes = [
  {
    path:'',
    name:'layout',
    component:Layout,
    children:[
      {
        path: '/',
        name: 'home',
        component: HomeView

      },{
        path: '/login', // 定义访问路径
        name: 'Login', // 路由名称（可选）
        component: Login // 引入的组件
      },{
        path: '/register', // 定义访问路径
        name: 'register', // 路由名称（可选）
        component: Register // 引入的组件
      },
      {
        path: '/',
        name: 'changePassword',
        component: changePassword
      },
      {
        path: '/',
        name: 'InformationAdmin',
        component: InformationAdmin
      }
    ]
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

export default router
