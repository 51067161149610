<template>
  <div id="app">
    <!-- <MyHead @changeMenu='changeMenu'/> -->
    <layout :menuId ='menuId'></layout>
  </div>
</template>

<script>
import layout from './views/Layout.vue'
import MyHead from './components/myHead.vue'
export default {
  components: {
    layout,
    MyHead
  },
  data(){
    return {
      menuId:1
    }
  },
  methods:{
    changeMenu(val){
      console.log(val);
      this.menuId=val
    }
  }
};
</script>
<style lang="scss">
   #app{
    // height: 100%;
   }
  .el-message{
     height: 100px !important;

    .el-message__content{
      font-size: 20px!important;
    }
    .el-icon-error{
      font-size: 20px !important;
    }
   }

</style>
