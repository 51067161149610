import request from '@/utils/request'

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/dev-api/login',
    headers: {
      isToken: false,
      repeatSubmit: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/dev-api/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

/* // 获取用户详细信息
export function getInfo() {
  return request({
    url: '/dev-api/getInfo',
    method: 'get'
  })
} */

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/dev-api/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
//xmm
export function evidence(data) {
  return request({
    url: '/dev-api/evidence',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
import axios from 'axios';

// 获取用户详细信息，接受一个token作为参数
export function getInfo(token) {
  return axios({
    url: '/dev-api/getInfo',
    method: 'get',
    headers: {
      'Authorization': `Bearer ${token}` // 假设你的后端需要Bearer token
      // 如果你的后端需要其他形式的token，比如'x-access-token'，则替换为：
      // 'x-access-token': token
    }
  });
}
// 获取用户详细信息
export function queryViewDetails(data) {
  return request({
    url: '/dev-api/evidence/queryViewDetails',
    method: 'get',
	params: data
  })
}

export  function putPassword(data){
  return request({
    url:'dev-api/newLogin/putPassword',
    method:'put',
    data:data
  })
}
