
import base  from './base'
import axios from '../axios/request'
import request from "@/utils/request";

const  http= {
    // testGet:function(params){
    //     return axios.get(base.test,{params})
    // },
    // testPost:function(params){
    //     return axios.post(base.test,params)
    // }
}
export default  http

export function uploadImg(file) {
    const formData = new FormData();
    formData.append('file', file); // 'file' 是后端接收的字段名

    return request({
        url: '/dev-api/upload/uploadImage',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
export function  commissionAdd(data){
   return request({
       url:'/dev-api/commission/add',
       method:'post',
       data:data
   })
}

/**
 * 委托列表
 * @param data
 * @returns {*}
 */

export  function  commissionList(data){
    return request({
        url:'/dev-api/commission/list',
        method:'get',
        params:data
    })
}

/**
 * 根据id查看存证详情
 */
export  function  evidenceById(id){
    return request({
        url:'/dev-api/evidence/id?id='+id,
        method:'get'
    })
}

/**
 *
 */
export  function  queryUser(params){
    return request({
        url:'/dev-api//newLogin/queryUser',
        method:'get',
        params:params
    })
}
