<template>
    <div class="footerBox">
<!--         <div class="footer"> 奇蚁科技 版权所有     客服电话：4008509168</div>-->
    </div>
</template>

<script>
export default {

}
</script>

<style lang='scss' scoped>
.footerBox{

}
.footer{
    position: absolute;
    bottom: 30px;
    width: 100%;
    font-size: 16px;
    color: #777;
    text-align: center;
}

</style>
