<template>
    <div class="container">
  <el-card class="box-card" v-if="!isUserListShow">
    <el-tabs v-model="activeNameTwo" type="card" @tab-click="handleClickTwo">
    <el-tab-pane class="elTab" label="审核列表" name="first">
<!--    <div class="H2class">-->
<!--  <div slot="header" class="clearfix" v-if="!commissionShow" >-->
<!--    <h2 @click="toExamine()">审核数据</h2>-->
<!--  </div>-->
<!--    <div slot="header" class="clearfixRight" v-if="!commissionShow">-->
<!--      <h2 @click="toUser()">会员列表</h2>-->
<!--    </div>-->
<!--    </div>-->
  <div class="tabBox" v-if="!commissionShow">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="审核通过" name="first">

        <div class="search-box" style="margin-bottom: 20px; display: flex; align-items: center;">
          <span style="margin-right: 10px;">提交账号：</span>
          <el-input
              v-model="userName"
              placeholder="请输入手机号进行查询"
              clearable
              style="width: 300px; margin-right: 10px;"
          ></el-input>
          <span style="margin-right: 10px;">成果名称：</span>
          <el-input
              v-model="achievementName"
              placeholder="请输入成果名称进行查询"
              clearable
              style="width: 300px;"
          ></el-input>
          <!-- 查询按钮 -->
          <el-button type="primary" @click="filterByPhoneNumber(2)">查询</el-button>
          <!-- 重置按钮 -->
          <el-button @click="resetSearch(2)" style="margin-left: 10px;">重置</el-button>
        </div>
        <div class="itemBox" v-if="passReview.length>0">

          <el-table :data="passReview" border style="width: 100%">
            <el-table-column prop="createTime" label="日期" align="center">
            </el-table-column>
            <el-table-column prop="achievementName" label="成果名称" align="center">
            </el-table-column>
            <el-table-column prop="holdingUnit" label="持有单位/人" align="center">
            </el-table-column>
            <el-table-column prop="completionUnit" label="完成单位/人" align="center">
            </el-table-column>
            <el-table-column prop="hashValue" label="哈希值" align="center">
            </el-table-column>
            <el-table-column prop="certificateNo" label="证书编号" align="center">
            </el-table-column>
            <el-table-column prop="phoneNumber" label="手机号" align="center">
            </el-table-column>
            <el-table-column prop="userName" label="提交账号" align="center">
            </el-table-column>
            <el-table-column prop="certificateUrl" label="证书" align="center">
              <template #default="scope">
                <el-link
                    type="primary"
                    :href="scope.row.certificateUrl"
                    target="_blank"
                    :underline="false">
                  查看
                </el-link>
              </template>
            </el-table-column>
            <el-table-column prop="certificateUrl" label="资料" align="center">
              <template #default="scope">
                <el-button
                    type="primary"
                    size="small"
                    :loading="scope.row.downloading"
                    @click="handleDownload(scope.row)"
                >
                  {{ scope.row.downloading ? '下载中...' : '下载' }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="评估">
              <template #default="scope">
                  <span>
<!--                   委托评估-->
                    {{ scope.row.isValue === 0 ? "自我评估" : "委托评估" }}
                  </span>
                <span v-if="scope.row.isValue !==0" @click="viewContent(scope.row)" class="clickable">
                （查看）
              </span>
              </template>
            </el-table-column>
            <el-table-column label="详情查看" align="center" v-if="">

              <template #default="scope">
                <el-link
                    type="primary"
                    target="_blank"
                    :underline="false"
                    @click="evidenceById(scope.row.id)"
                >
                  查看
                </el-link>
              </template>
            </el-table-column>


          </el-table>

          <el-pagination
              @size-change="handleSizeChangeFirst"
              @current-change="handleCurrentChangeFirst"
              :page-sizes="[1,10, 20, 50, 100]"
              :page-size="pageSizeFist"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              class="pageList"/>
        </div>
        <div class="itemBox" v-else>
          <div class="empty">
            <img src="@/assets/img/noUser.png" alt="" class="noData" />
            <div class="emptyText">暂无数据</div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="审核失败" name="second">
        <div class="search-box" style="margin-bottom: 20px; display: flex; align-items: center;">
          <span style="margin-right: 10px;">提交账号：</span>
          <el-input
              v-model="userName"
              placeholder="请输入手机号进行查询"
              clearable
              style="width: 300px; margin-right: 10px;"
          >

          </el-input>
          <span style="margin-right: 10px;">成果名称：</span>
          <el-input
              v-model="achievementName"
              placeholder="请输入成果名称进行查询"
              clearable
              style="width: 300px;"
          ></el-input>
          <el-button type="primary" @click="filterByPhoneNumber(1)">查询</el-button>
          <!-- 重置按钮 -->
          <el-button @click="resetSearch(1)" style="margin-left: 10px;">重置</el-button>
        </div>


        <div class="itemBox" v-if="underReview.length>0">

          <el-table :data="underReview" border style="width: 100%">
            <el-table-column prop="createTime" label="失败时间
" align="center">
            </el-table-column>
            <el-table-column prop="achievementName" label="成果名称" align="center">
            </el-table-column>
            <el-table-column prop="holdingUnit" label="持有单位/人" align="center">
            </el-table-column>
            <el-table-column prop="completionUnit" label="完成单位/人" align="center">
            </el-table-column>
            <el-table-column prop="phoneNumber" label="手机号" align="center">
            </el-table-column>
            <el-table-column prop="userName" label="提交账号" align="center">
            </el-table-column>
            <el-table-column prop="certificateUrl" label="资料" align="center">
              <template #default="scope">
                <el-button
                    type="primary"
                    size="small"
                    :loading="scope.row.downloading"
                    @click="handleDownload(scope.row)"
                >
                  {{ scope.row.downloading ? '下载中...' : '下载' }}
                </el-button>
              </template>
            </el-table-column>

            <el-table-column label="评估">
              <template #default="scope">
                  <span>
<!--                   委托评估-->
                    {{ scope.row.isValue === 0 ? "自我评估" : "委托评估" }}
                  </span>
                <span v-if="scope.row.isValue !==0" @click="viewContent(scope.row)" class="clickable">
                （查看）
              </span>
              </template>
            </el-table-column>
            <el-table-column label="详情查看" align="center" v-if="">

              <template #default="scope">
                <el-link
                    type="primary"
                    target="_blank"
                    :underline="false"
                    @click="evidenceById(scope.row.id)"
                >
                  查看
                </el-link>
              </template>
            </el-table-column>

          </el-table>
          <el-pagination
              @size-change="handleSizeChangeTwo"
              @current-change="handleCurrentChangeTwo"
              :page-sizes="[1,10, 20, 50, 100]"
              :page-size="pageSizeTwo"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              class="pageList"/>
        </div>
        <div class="itemBox" v-else>
          <div class="empty">
            <img src="@/assets/img/noUser.png" alt="" class="noData" />
            <div class="emptyText">暂无数据</div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="审核中" name="third">
        <div class="search-box" style="margin-bottom: 20px; display: flex; align-items: center;">
          <span style="margin-right: 10px;">提交账号：</span>
          <el-input
              v-model="userName"
              placeholder="请输入手机号进行查询"
              clearable
              style="width: 300px; margin-right: 10px;"
          ></el-input>
          <span style="margin-right: 10px;">成果名称：</span>
          <el-input
              v-model="achievementName"
              placeholder="请输入成果名称进行查询"
              clearable
              style="width: 300px;"
          ></el-input>
          <el-button type="primary" @click="filterByPhoneNumber(0)">查询</el-button>
          <!-- 重置按钮 -->
          <el-button @click="resetSearch(0)" style="margin-left: 10px;">重置</el-button>
        </div>
        <div class="itemBox" v-if="pendingReview.length">

          <!--              {{pendingReview}}-->
          <el-table :data="pendingReview" border style="width: 100%">
            <el-table-column prop="createTime" label="提交时间" align="center">
            </el-table-column>

            <el-table-column prop="achievementName" label="成果名称" align="center">
            </el-table-column>
            <el-table-column prop="holdingUnit" label="持有单位/人" align="center">
            </el-table-column>
            <el-table-column prop="completionUnit" label="完成单位/人" align="center">
            </el-table-column>
            <el-table-column prop="phoneNumber" label="手机号" align="center">
            </el-table-column>
            <el-table-column prop="userName" label="提交账号" align="center">
            </el-table-column>
            <el-table-column prop="certificateUrl" label="资料" align="center">
              <template #default="scope">
                <el-button
                    type="primary"
                    size="small"
                    :loading="scope.row.downloading"
                    @click="handleDownload(scope.row)">
                  {{ scope.row.downloading ? '下载中...' : '下载' }}
                </el-button>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="center" v-if="showAdmin">
              <template #default="scope">
                <div class="button-group">
                  <button class="custom-buttonPass " @click="handleApprove(scope.row)">通过</button>
                  <button class="custom-buttonRefuse"  @click="handleReject(scope.row)">拒绝</button>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="详情查看" align="center" v-if="">

              <template #default="scope">
                <el-link
                    type="primary"
                    target="_blank"
                    :underline="false"
                    @click="evidenceById(scope.row.id)"
                >
                  查看
                </el-link>
              </template>
            </el-table-column>
            <el-table-column label="评估">
              <template #default="scope">
                  <span>
<!--                   委托评估-->
                    {{ scope.row.isValue === 0 ? "自我评估" : "委托评估" }}
                  </span>
                <span v-if="scope.row.isValue !==0" @click="viewContent(scope.row)" class="clickable">
                （查看）
              </span>
              </template>
            </el-table-column>
          </el-table>
                      <el-pagination
                          @size-change="handleSizeChangeThree"
                          @current-change="handleCurrentChangeThree"
                          :page-sizes="[1,10, 20, 50, 100]"
                          :page-size="pageSizeThree"
                          layout="total, sizes, prev, pager, next, jumper"
                          :total="total"
                          class="pageList"/>
        </div>


        <div class="itemBox" v-else>
          <div class="empty">
            <img src="@/assets/img/noUser.png" alt="" class="noData" />
            <div class="emptyText">暂无数据</div>
          </div>
        </div>

      </el-tab-pane>
      <el-tab-pane label="委托评估" name="four">
        <div class="search-box" style="margin-bottom: 20px; display: flex; align-items: center;">
          <span style="margin-right: 10px;">提交账号：</span>
          <el-input
              v-model="userName"
              placeholder="请输入手机号进行查询"
              clearable
              style="width: 300px;"
          >

          </el-input>
          <el-button type="primary" @click="con()">查询</el-button>
          <!-- 重置按钮 -->
          <el-button @click="conClean()" style="margin-left: 10px;">重置</el-button>
        </div>
        <div class="itemBox" v-if="commissionList.length">
        <el-table :data="commissionList" border style="width: 100%">
          <el-table-column prop="principalNit" label="委托人单位" align="center">
          </el-table-column>
          <el-table-column prop="client" label="委托人姓名" align="center">
          </el-table-column>
          <el-table-column prop="contactInformation" label="委托人联系电话" align="center">
        </el-table-column>
          <el-table-column prop="createTime" label="委托创建时间" align="center">
          </el-table-column>
          <el-table-column prop="userName" label="提交账号" align="center">

          </el-table-column>
          <el-table-column prop="notes" label="备注" align="center">
        </el-table-column>
        </el-table>
          <el-pagination

            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[1,10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        class="pageList"/>
        </div>
      <div class="itemBox" v-else>
        <div class="empty">
          <img src="@/assets/img/noUser.png" alt="" class="noData" />
          <div class="emptyText">暂无数据</div>
        </div>
      </div>
      </el-tab-pane>
    </el-tabs>

  </div>

    <div v-if="commissionShow">
      <div class="returnClass" @click="goBack()">
        <img src="../assets/img/return.svg" alt="返回图标" class="icon"  />
        <span class="returnSpanClass" >返回上一页</span>
      </div>
      <form>
        <div class="form-row">
                  <label class="form-label">成果名称:</label>
                  <el-input type="text" class="form-input"
                         disabled
                         v-model="form.achievementName"/>
                </div>
      </form>
      <div class="form-row">
        <label class="form-label">持有单位/人:</label>
        <el-input type="text" class="form-input"
               v-model="form.holdingUnit" disabled/>
      </div>
      <div class="form-row">
        <label class="form-label">完成单位/人:</label>
        <el-input type="text" class="form-input"
               v-model="form.completionUnit" disabled/>
      </div>
      <div class="form-row">
        <label class="form-label">联系电话:</label>
        <el-input type="text" class="form-input"  v-model="form.phoneNumber" disabled />
      </div>
      <div class="form-row">
        <label class="form-label">完成时间:</label>
        <el-date-picker class="dataclass"
                        v-model="form.completionTime"
                        type="date"
                        disabled>
        </el-date-picker>
      </div>
      <div class="rights-section">
        <label class="form-label required">技术领域:</label>
        <div class="rights-grid">
          <div class="right-optionValue" v-for="right in technicalFieldList" :key="right">
            <input type="radio" :value="right" v-model="form.technicalField" disabled>
            <label>{{ right }}</label>
          </div>
        </div>
      </div>
      <div class="rights-section">
        <label class="form-label required">价值评估:</label>
        <div class="rights-grid">
          <div class="right-optionValue" v-for="right in rightsP" :key="right" >
            <input
                type="radio"
                :value="right"
                v-model="form.valueAssessment"
               disabled
            >
            <label>
              {{ right }}
<!--              &lt;!&ndash; 仅在点击括号里的文字时跳转 &ndash;&gt;-->
<!--              <span class="clickable">（委托专家评估）-->
<!--          </span>-->
            </label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <label class="form-label required">金额(万元):</label>
        <el-input type="number" class="form-input" placeholder="请输入金额" v-model="form.money" disabled/>
      </div>

      <div class="rights-section">
        <label class="form-label">转化方式:</label>
        <div class="rights-grid">
          <div class="right-option" v-for="right in rights" :key="right">
          <input type="checkbox" :value="right" v-model="form.conversionMethod" disabled>
            <label>{{ right }}</label>
          </div>
        </div>
      </div>




      <div class="form-row">
        <label class="form-label required">成果介绍:</label>
        <el-input type="textarea"
                  show-word-limit
                  :maxlength="100"
                  class="form-input"
                  disabled
                  v-model="form.introductionAchievements"
        />
      </div>
      <div class="form-row">
        <label class="form-label required">核心技术:</label>
        <el-input type="textarea"
                  class="form-input"
                 disabled
                  show-word-limit
                  :maxlength="100"
                  v-model="form.coreTechnology"/>
      </div>
      <div class="form-row">
        <label class="form-label required">市场前景:</label>
        <el-input type="textarea"
                  class="form-input"
                  show-word-limit
                  :maxlength="100"
                  disabled
                  v-model="form.marketProspect"/>
      </div>



    </div>
    </el-tab-pane>
      <el-tab-pane  class="elTab" label="会员列表" name="two">
        <div class="search-box" style="margin-bottom: 20px; display: flex; align-items: center;">
          <span style="margin-right: 10px;">提交账号：</span>
          <el-input
              v-model="userName"
              placeholder="请输入手机号进行查询"
              clearable
              style="width: 300px;"
          >

          </el-input>
          <el-button type="primary" @click="queryUser()">查询</el-button>
          <!-- 重置按钮 -->
          <el-button @click="queryUserClean()" style="margin-left: 10px;">重置</el-button>
        </div>
        <div class="itemBox" v-if="userList.length">
        <el-table :data="userList" border style="width: 100%">

          <el-table-column prop="userName" label="手机号" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center">
          </el-table-column>
          <el-table-column prop="companyName" :label="userInfo.individual === 1 ? '姓名' : '公司名'" align="center">
          </el-table-column>
          <!--          <el-table-column prop="businessLicense" label="手机号" align="center">-->
          <!--          </el-table-column>-->
          <el-table-column prop="businessLicense" :label="userInfo.individual === 1 ? '身份证' : '营业执照'" align="center">
            <template #default="scope">
              <img
                  :src="scope.row.businessLicense"
                  :alt="userInfo.individual === 1 ? '身份证' : '营业执照'"
                  style="max-width: 100px; height: auto; cursor: pointer;"
              />

            </template>
          </el-table-column>
          <el-table-column :prop="userInfo.individual === 1 ?'个人':'公司'" label="类型" align="center">
            <span v-if="userInfo.individual === 1" >
              个人
            </span>
            <span v-else>
              公司
            </span>

          </el-table-column>
          <el-table-column prop="certificateUrl" label="重置密码" align="center">
            <template #default="scope">
              <el-button
                  type="primary"
                  size="small"
                  @click="resetPassword(scope.row)"
              >
                重置密码
              </el-button>
            </template>
          </el-table-column>

        </el-table>
          <el-pagination
              @size-change="handleSizeChangeUser"
              @current-change="handleCurrentChangeUser"
              :page-sizes="[1,10, 20, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              class="pageList"/>
        </div>
        <div class="itemBox" v-else>
          <div class="empty">
            <img src="@/assets/img/noUser.png" alt="" class="noData" />
            <div class="emptyText">暂无数据</div>
          </div>
        </div>
      </el-tab-pane>


    </el-tabs>
  </el-card>

      <el-card  v-else>
        <div class="H2class">
          <div slot="header" class="clearfix" v-if="!commissionShow">
            <h2 @click="toExamine()">审核数据</h2>
          </div>
          <div slot="header" class="clearfixRight" v-if="!commissionShow">
            <h2 >会员列表</h2>
          </div>
        </div>

      </el-card>

  </div>


</template>

<script>
import {
  putPassword,
  queryViewDetails
} from "@/api/login";
import {download, updateEvidence} from "@/api/certificate";
import axios from "axios";
import {commissionList, evidenceById, queryUser} from "@/api/http";
export default {
  data() {
    return {
      pageSizeThree:100,
      pageNumThree:1,
      pageSizeTwo:100,
      pageNumTwo:1,
      achievementName:"",
      pageSizeFist:100,
      pageNumFirst:1,
      //
      userName:"",

      activeNameTwo:"first",
      pageSize:100,
      pageNum:1,
      total:0,
      queryParams: {
        pageNum: 1,
        pageSize: 100
      },
      isUserListShow:false,
      //用户列表
      userList:[],
      // userList:{
      //   userName:"",
      //   createTime:"",
      //   companyName:"",
      //   businessLicense:"",
      //   individual:"",
      // },
      form: {
        achievementName: "",//成果名称
        holdingUnit: "",//持有单位/人
        completionUnit: "",//完成单位/人
        phoneNumber: "", //联系电话
        completionTime: "",//完成时间
        introductionAchievements: "",//成果介绍
        coreTechnology: "",//核心技术
        marketProspect: "",//市场前景
        money: "",//金额
        //旧的
        depositSubject: "", // 存证主体
        isThirdParty: false, // 是否为第三方机构
        author: "", // 作者
        authorId: "", // 作者证件
        authorIdNumber: "", // 作者证件号码
        memberId: "", // 会员编号
        uploadFiles: "", // 上传文件
        depositName: "", // 存证名称
        depositType: "", // 存证类型
        equityOwnership: [], // 权益拥有
        originalFilenameUrl: "",//文件上传路径
        evaluationDocuments: "",//评估证书路径
        originalFilenameUrlName: "",//评估证书名
        conversionMethod:"",//转化方式
        //价值评估
        valueAssessment: "",
        //技术领域
        technicalField: "",
      },
      showAdmin:false,
      activeName: "first",
      // 个人信息
      userInfo: {
        name: "",
        email: "",
        phone: "",
        address: "",
        companyName: "",
        businessLicense: "",
        individual:0,
      },
      // 待审核数据
      pendingReview: [

      ],
      rights: [
        "自行实施转化",
        "科技成果转让",
        "科技成果许可",
        "合作实施转化",
        "科技成果作价投资",
        "其他",
      ],
      rightsP: [
        "自我估值",
        "专家估值（委托专家评估)"
      ],
      technicalFieldList: [
        "领域1",
        "领域2",
        "领域3",
        "领域4",
      ],
      // 审核失败
      passReview: [


      ],
      commissionShow:false,
      commissionList:[],
      // 审核成功数据
      underReview: [
      ],
    };
  },
  created() {
    // 组件创建时调用getUserInfo
    this.getuserInfo();
    this.getCheck(2);
    this.isAdmin();
    this.commissionListTab();
  },

  methods: {

    queryUserClean(){
      this.userName = ""

        const  params = {
          pageSize:this.pageSize,
          pageNum:this.pageNum,
          userName:this.userName
        }
        queryUser(params).then(res=>{
          this.userList = res.rows;
          this.total = res.total
        })
    },
    queryUser(){
      const  params = {
        pageSize:this.pageSize,
        pageNum:this.pageNum,
        userName:this.userName
      }
      queryUser(params).then(res=>{
        this.userList = res.rows;
        this.total = res.total
      })
    },
    handleSizeChangeUser(val){
      this.pageSize = val
      console.log("pagesize",this.pageSize)
      console.log("pageNum",this.pageNum)
      const  params = {
        pageSize:this.pageSize,
        pageNum:this.pageNum,
      }
      queryUser(params).then(res=>{
        this.userList = res.rows;
        this.total = res.total
      })
    },
    handleCurrentChangeUser(item){
      console.log("item",item)
      const  params = {
        pageSize:this.pageSize,
        pageNum:item,
      }
      queryUser(params).then(res=>{
        this.userList = res.rows;
        this.total = res.total
      })
    },
    con(){
      const  param ={
        pageSize:this.pageSize,
        pageNum:this.pageNum,
        userName:this.userName

      }
      commissionList(param).then(res=>{
        console.log("res.c",res.rows);
        this.total = res.total
        this.commissionList = res.rows;
      })
    },
    conClean(){
      this.userName =""
      const  param ={
        pageSize:this.pageSize,
        pageNum:this.pageNum,
      }
      commissionList(param).then(res=>{
        console.log("res.c",res.rows);
        this.total = res.total
        this.commissionList = res.rows;
      })
    },
    resetSearch(item){
      this.userName = ""
      this.achievementName =""
      var userId = localStorage.getItem("userId");
      console.log("userId", userId);
      if (userId == null) {
        alert("请先登录");
      }
      const data = {
        auditStatus: item,
        userId: userId,
        pageSize:this.pageSize,
        pageNum:this.pageNum,
        userName:this.userName
      }
      queryViewDetails(data).then(response => {
        if(item == "2"){
          this.passReview = response.rows
          this.total= response.total
          console.log("passReview",this.passReview)
        }else if(item == "1"){
          this.underReview = response.rows
          this.total= response.total
          console.log("underReview",this.underReview)
        }else if(item == "0"){
          this.pendingReview = response.rows
          this.total= response.total
          // const business = this.userInfo.businessLicense
          // this.pendingReview.push(business)
          // console.log("pendingReview",this.pendingReview)
        }
      })
    },

    filterByPhoneNumber(item){

      var userId = localStorage.getItem("userId");
      console.log("userId", userId);
      if (userId == null) {
        alert("请先登录");
      }
      if (item == "2"){
        this.pageNum = this.pageNumFirst
        this.pageSize = this.pageSizeFist
      }else if (item == "1"){
        this.pageNum = this.pageNumTwo
        this.pageSize = this.pageSizeTwo
      }else if (item == "0"){
        this.pageNum = this.pageNumThree
        this.pageSize = this.pageSizeThree
      }
      const data = {
        auditStatus: item,
        userId: userId,
        pageSize:this.pageSize,
        pageNum:this.pageNum,
        userName:this.userName,
        achievementName:this.achievementName
      }
      queryViewDetails(data).then(response => {
        if(item == "2"){
          this.passReview = response.rows
          this.total= response.total
          console.log("passReview",this.passReview)
        }else if(item == "1"){
          this.underReview = response.rows
          this.total= response.total
          console.log("underReview",this.underReview)
        }else if(item == "0"){
          this.pendingReview = response.rows
          this.total= response.total
          // const business = this.userInfo.businessLicense
          // this.pendingReview.push(business)
          // console.log("pendingReview",this.pendingReview)
        }
      })
    },
    handleSizeChangeFirst(val){
      console.log("val",val);
      this.pageSizeFist= val;
      var userId = localStorage.getItem("userId");
      console.log("userId", userId);
      if (userId == null) {
        alert("请先登录");
      }

      const data = {
        auditStatus: 2,
        userId: userId,
        pageSize:this.pageSizeFist,
        pageNum:this.pageNum,
        userName:this.userName,
        achievementName:this.achievementName
      }
      queryViewDetails(data).then(response => {
        this.passReview = response.rows
        this.total = response.total
      })

    },
    handleCurrentChangeFirst(val){
      this.pageNumFirst = val;
      var userId = localStorage.getItem("userId");
      console.log("userId", userId);
      if (userId == null) {
        alert("请先登录");
      }
      const data = {
        auditStatus: 2,
        userId: userId,
        pageSize:this.pageSizeFist,
        pageNum:this.pageNumFirst,
        userName:this.userName,
        achievementName:this.achievementName
      }
      queryViewDetails(data).then(response => {
        this.passReview = response.rows
        this.total = response.total
      })
    },


    handleSizeChangeTwo(val){
      console.log("gothis")
      this.pageSizeTwo = val;
      var userId = localStorage.getItem("userId");
      console.log("userId", userId);
      if (userId == null) {
        alert("请先登录");
      }
      const data = {
        auditStatus: 1,
        userId: userId,
        pageSize:this.pageSizeTwo,
        pageNum:this.pageNumTwo,
        userName:this.userName,
        achievementName:this.achievementName
      }
      queryViewDetails(data).then(response => {
        this.underReview = response.rows
        this.total = response.total
      })

    },
    handleCurrentChangeTwo(val){
      console.log("gothis",1)
      this.pageNumTwo = val;
      var userId = localStorage.getItem("userId");
      console.log("userId", userId);
      if (userId == null) {
        alert("请先登录");
      }
      const data = {
        auditStatus: 1,
        userId: userId,
        pageSize:this.pageSizeTwo,
        pageNum:this.pageNumTwo,
        userName:this.userName,
        achievementName:this.achievementName
      }
      queryViewDetails(data).then(response => {
        this.underReview = response.rows
        this.total = response.total
      })
    },
    handleSizeChangeThree(val){
      this.pageSizeThree = val;
      var userId = localStorage.getItem("userId");
      console.log("userId", userId);
      if (userId == null) {
        alert("请先登录");
      }
      const data = {
        auditStatus: 0,
        userId: userId,
        pageSize:this.pageSizeThree,
        pageNum:this.pageNumThree,
        userName:this.userName,
        achievementName:this.achievementName
      }
      queryViewDetails(data).then(response => {
        this.pendingReview = response.rows
        this.total = response.total
      })

    },
    handleCurrentChangeThree(val){
      this.pageNumThree = val;
      var userId = localStorage.getItem("userId");
      console.log("userId", userId);
      if (userId == null) {
        alert("请先登录");
      }
      const data = {
        auditStatus: 0,
        userId: userId,
        pageSize:this.pageSizeThree,
        pageNum:this.pageNumThree,
        userName:this.userName,
        achievementName:this.achievementName
      }
      queryViewDetails(data).then(response => {
        this.pendingReview = response.rows
        this.total = response.total
      })
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.commissionListTab()

    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum =val
      this.commissionListTab()
    },


    resetPassword(item){
      console.log("item",item);
        this.userId = localStorage.getItem("userId")
        console.log("userID",this.userId);
        const  data={
          password:'123456',
          phone: item.userName
        }
        putPassword(data).then(res=> {
          console.log("res", res);
          if (res.code === 200) {
            this.$message({
              message: '重置成功，密码为：‘123456’，请联系用户通过找回密码功能修改自己密码',
              type: 'success',
              duration: 3000,
              showClose: true
            })
          }
          })
        },

    toExamine(){
      this.isUserListShow = false;
    },
    toUser(){
      this.queryUserList();
      this.isUserListShow = true;
    },
    goBack(){
      // console.log("走了吗");
      this.commissionShow = false;
    },

    queryUserList(){
      const  params = {
        pageSize:this.pageSize,
        pageNum:this.pageNum,
      }
      queryUser(params).then(res=>{
        this.userList = res.rows;
        this.total = res.total
      })
    },
    //
    evidenceById(item){
      this.commissionShow = true;
      console.log("item",item)
      evidenceById(item).then(res=>{
        this.form = res.data
        this.commissionShow = true;
        console.log("res",res.data.valueAssessment);
        this.form.conversionMethod = res.data.conversionMethod.split(",")
      })
    },

    //
    commissionListTab(){
      const  param ={
        pageSize:this.pageSize,
        pageNum:this.pageNum,

      }
      commissionList(param).then(res=>{
        console.log("res.c",res.rows);
        this.total = res.total
        this.commissionList = res.rows;
      })
    },

    viewContent(data){

      console.log("data",data);
      console.log("data.evaluationDocuments",data.evaluationDocuments);
      if (data.evaluationDocuments !== null && data.evaluationDocuments !== undefined && data.evaluationDocuments !==""){
        window.open(data.evaluationDocuments)
      }else {
        console.log("list",data.commissionList)
        this.commissionList = data.commissionList[0];
        this.commissionShow = true;
      }
    },
    rightText(right) {
      return right.replace("（查看)", "");
    },
    openUrl(url){
      window.open(url)
    },
    async handleDownload(row) {
      // 设置下载状态
      window.location.href = 'http://kjcgcz.sdgtda.cn/dev-api/evidence/download/' + row.id
    },
    handleApprove(row) {
      console.log(row)
      const data =
          {
            id:row.id,
            auditStatus: 2
          };
      updateEvidence(data)
          .then(responses => {
            this.$message({
              message: '该审核已通过',
              type: 'success',
              duration: 3000,
              showClose: true,
              offset:300
            })
            this.getCheck(0) ;
          });

    },
    handleReject(row) {
      console.log(row);

      const data ={
        id:row.id,
        auditStatus: 1
      }
      updateEvidence(data).then(res=>{
        console.log("res",res)
        this.$message({
          message: '审核已拒绝',
          type: 'error',
          duration: 3000,
          showClose: true
        })
        this.getCheck(0)  // 假设你有一个获取列表数据的方法

      })
    },
    isAdmin() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem("userId");
      console.log("userId", userId);
      if (userId === '1'){
        this.showAdmin = true
        console.log("1")
      }else {
        this.showAdmin = false
      }
// 假设token解析后的格式是这样的：{ "role": "admin" }

// 判断用户是否为管理员
      if (userId === 'admin') {
        // 是管理员的处理逻辑
        console.log('当前用户是管理员');
      } else {
        // 不是管理员的处理逻辑
        console.log('当前用户不是管理员');
      }
    },
    //个人信息
    getuserInfo() {
      var userInfos = localStorage.getItem("userInfos");
      // 将JSON字符串解析回对象，并赋值给组件的data属性
      var userInfoObj = JSON.parse(userInfos);
      // 正确的赋值方式
      this.userInfo.name = userInfoObj.userName; // 注意这里是赋值，不是函数调用
      this.userInfo.email = userInfoObj.email;
      this.userInfo.phone = userInfoObj.phonenumber; // 注意这里应该是phonenumber，而不是email
      this.userInfo.companyName = userInfoObj.companyName;
      this.userInfo.businessLicense = userInfoObj.businessLicense;
      this.userInfo.individual = userInfoObj.individual;
    },
    handleClick(tab, event) {
      this.userName = ""
      this.achievementName =""
      console.log("i")
      if(tab.index==="0"){

        this.getCheck(2);

      }else if(tab.index==="1"){
        this.getCheck(1);

      }else if(tab.index==="2"){
        this.getCheck(0);

      }else if(tab.index === "3"){
        this.commissionListTab()
      }

    },
    handleClickTwo(tab,event){
      this.userName = ""
      this.achievementName =""
      console.log("tab",tab);
      if (tab.index ==="0"){

  this.getCheck(2)
      }else {
        this.queryUserList();
      }
    },
    getCheck(status) {
      //获取审核信息
      var userId = localStorage.getItem("userId");
      console.log("userId", userId);
      if (userId == null) {
        alert("请先登录");
      }
      if (status == "2"){
        this.pageNum = this.pageNumFirst
        this.pageSize = this.pageSizeFist
      }else if (status == "1"){
        this.pageNum = this.pageNumTwo
        this.pageSize = this.pageSizeTwo
      }else if (status == "0"){
        this.pageNum = this.pageNumThree
        this.pageSize = this.pageSizeThree
      }
      const data = {
        auditStatus: status,
        userId: userId,
        pageSize:this.pageSize,
        pageNum:this.pageNum,
      }
      queryViewDetails(data).then(response => {
        console.log("re",response)
        if(status == "2"){
          this.passReview = response.rows
          this.total = response.total;
          console.log("passReview",this.passReview)
        }else if(status == "1"){
          this.underReview = response.rows
          this.total = response.total;
          console.log("underReview",this.underReview)
        }else if(status == "0"){
          this.pendingReview = response.rows
          this.total = response.total;
          // const business = this.userInfo.businessLicense
          // this.pendingReview.push(business)
          // console.log("pendingReview",this.pendingReview)
        }




      }).catch(error => {
        console.error("请求失败", error);
      });

    }
  },
};
</script>
<style scoped lang="scss">
.clickable {
  color: #0077ff;
  cursor: pointer;
  //text-decoration: underline;
}

::v-deep .el-message {
  height: 80px !important;
}
.container {
  margin: 0 auto;
  padding: 20px;
}
.personal-info,
.review-section {
  margin-bottom: 30px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
h2 {
  color: #333;
  margin-bottom: 10px;
}
h3 {
  color: #666;
  margin-bottom: 8px;
}
.info p,
.review-category p {
  margin: 5px 0;
  text-align: left;
}
.review-category {
  margin-top: 20px;
}
.info p {
  margin-bottom: 16px;
  font-size: 16px;
}
.userInfo {
  margin-bottom: 50px;
}
.itemBox {
  position: relative;
  padding: 20px;
  min-height: 250px;
  //height: 400px;
  //overflow-y: auto;
  .item {
    margin-bottom: 20px;
    font-size: 16px;
    .item_info {
      display: flex;
    }
  }
  .empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .noData {
    width: 200px;
  }
}
.el-message-box {
  padding: 20px;
  border-radius: 4px;
}

.el-message-box__header {
  padding: 15px !important;
}

.el-message-box__title {
  font-size: 16px !important;
  color: #333 !important;
}

.el-message-box__content {
  padding: 20px !important;
}

.el-message-box__btns {
  padding: 10px 20px 20px !important;
}

.el-button {
  margin: 0 10px !important;
}
.radio-option{
  font-size: 16px;
}
.el-message--success{
  font-size: 20px;
  margin-bottom: 20%;
}

.el-message-box__header {
  .button {
    display: none !important;
  }
}

.custom-buttonPass{
  padding: 9px 15px;
  font-size: 12px;
  //border-radius: 3px;
  color: #FFF;
  background-color: #409EFF;
  border-color: #409EFF;
}

.custom-buttonRefuse {
  padding: 9px 15px;
  font-size: 12px;
  //border-radius: 3px;
  color: #FFF;
  background-color: #ff405d;
  border-color: #ff405d;
  margin-top: 5px;
}

.form-row{
  //display: flex;
  //align-items: center;
  margin-bottom: 20px;
}


.form-input{
  display: block;
  margin-bottom: 8px;
  font-size: 20px;
  width: 100%;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.form-label{
  font-size: 20px;
}

.right-optionValue {
  width: 30%;
  display: flex;
  align-items: center;

  font-size: 20px;
  margin-bottom: 15px;

  input {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  label {
    color: #333;
  }
}
.rights-grid {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.right-option {
  width: 25%;
  display: flex;
  align-items: center;

  font-size: 20px;
  margin-bottom: 15px;

  input {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  label {
    color: #333;
  }
}
.el-card__header{
  display: flex !important;
}
.H2class{
  display: flex;
}
.clearfixRight{
  margin-left: 20px;
  margin-bottom: 20px;
}
.returnClass{
  font-size: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  align-items:center;
  display: flex;
}
.icon{
  width: 40px;
  height: 40px;
}

.returnSpanClass{
  height: 40px;
  align-items: center;
  margin-top: 20px;
  margin-left: 10px;
}
.clearfixRight{
  cursor: pointer;
}
.clearfix{
  cursor: pointer;
  .hover-effect {
    color: black;
    background-color: white;
    padding: 10px;
    text-align: center;
  }

  .hover-effect:hover {
    color: white;
    background-color: blue;
  }
}
.pageList{
  margin-top: 30px;
  /* align-content: center; */
  display: flex;
  justify-content: center;
}
::v-deep .elTab{
  font-size: 20px !important;
}
.search-box {
  font-size: 20px;
}
.search-box{
  font-size: 20px;
}
</style>
