

<template>

  <div class="login-page">
    <div class="login-form">
      <div class="loginlogo"  @click="showPageHome()">
        <img src="/long1.png" class="pointer" style="width:80%">
      </div>

      <p class="textzc">找到密码？ <a href="/login" class="switch">直接登录</a></p>

      <form @submit.prevent="handleLogin" >
        <div class="form-group">
          <input type="text" id="phone" v-model="phone" required placeholder="请输入您的手机号">
        </div>

        <div class="form-group">
          <input type="password" id="password" v-model="password" required placeholder="请输入新密码">
        </div>
        <div class="form-group">
          <input type="password" id="password" v-model="twoPassword" @change="twoPasswordChange()" required placeholder="请再次输入密码">
        </div>

        <button type="submit" @click="handleLoginNew()" style="height:50px;font-size:18px;font-weight: 500;" class="changeButton">修改密码</button>
      </form>


    </div>
  </div>
</template>
<script >
import changePassword from "@/views/xinxi.vue";
import {putPassword} from "@/api/login";

export  default {
  components: {changePassword},
  data(){
    return{
      phone:"",
      password:"",
      twoPassword:"",
      userId:0,
    }
  },
  methods:{
    handleLoginNew(){
     this.userId = localStorage.getItem("userId")
      console.log("userID",this.userId);
      const  data={
        password:this.password,
        phone: this.phone
      }
      putPassword(data).then(res=>{
        console.log("res",res);
        if (res.code ===200){
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 3000,
            showClose: true
          })
          setTimeout(()=>{
            this.$router.push('/login')
          },2000)

        }
      })
    },

    showPageHome(){
      this.$router.push({name:'home'})
    },
    twoPasswordChange(){
      if (this.password !== this.twoPassword){
        this.$message({
          message: '您两次输入的密码不同，请仔细核对',
          type: 'error',
          duration: 3000,
          showClose: true
        })
        this.twoPassword =""
      }
    }
  }
}



</script>


<style scoped lang="scss">

.changeButton{
  width: 100%;
  padding: 10px;
  background-color: #2238c2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}
</style>
