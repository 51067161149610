<template>
  <div class="layout">
    <!-- <MyHead /> -->
    <router-view></router-view>
    <myFooter/>
  </div>
</template>

<script>
import myFooter from '@/components/footer.vue';
import MyHead from "../components/myHead.vue";
export default {
  components: {
    MyHead,
    myFooter
  }
};
</script>

<style scoped lang="scss">
.layout{
  position: relative;
  width: 100%;
  //background: rgb(244, 246, 248)
}
</style>
