<template>
  <div class="login-page">
    <div class="login-form">
      <div class="loginlogo"  @click="showPageHome()">
        <img src="/long1.png" class="pointer" style="width:80%">
      </div>


      <p class="textzc">已有账号？ <a href="/login" class="switch">直接登录</a></p>

      <form @submit.prevent="handleLogin">

        <div class="form-group" >
          <el-radio v-model="radio" label="1"  @change="changeRadio(radio)">个人</el-radio>
          <el-radio v-model="radio" label="2" @change="changeRadio(radio)">企业</el-radio>
        </div>

        <div class="form-group">
          <input type="text" id="phone" v-model="phone" required placeholder="请输入手机号">
        </div>
        <div class="form-group" v-if="!individualShow">
          <input type="text" id="company" v-model="companyName" required placeholder="请输入公司名" >
        </div>
        <div class="form-group" v-if="individualShow">
          <input type="text" id="company" v-model="companyName" required placeholder="请输入您的姓名"  >
        </div>
        <div class="form-group photo-upload-with-preview" style="display:block">
          <div class="file-upload">
            <input type="file" accept="image/*" id="fileInput" class="hidden-file-input" @change="handleFileChange">
            <label for="fileInput" class="custom-file-label" v-if="!individualShow" >
              <span>上传营业执照</span>
              <!-- 你可以在这里添加图标或其他装饰 -->
            </label>
            <label for="fileInput" class="custom-file-label" v-else>
              <span>上传身份证照片</span>
              <!-- 你可以在这里添加图标或其他装饰 -->
            </label>
          </div>
          <div v-if="imagePreview" class="preview-container">
            <img :src="imagePreview" alt="照片预览" class="preview-image" style="width:50%;max-height:200px">
          </div>
        </div>


        <div class="form-group">
          <input type="text" id="captcha" v-model="captcha" required placeholder="请输入验证码" style="display:inline-block;width:70% !important">
          <img   :src="codeUrl" style="display:inline-block;width:30% !important;height: 50px;" @click="captchaNew()">
        </div>

        <div class="form-group">
          <input type="password" id="password" v-model="password" required placeholder="请输入密码">
        </div>

        <div class="form-group">
          <input type="password" id="password" v-model="passwordTwo" required placeholder="请再次输入密码">
        </div>
        <div class="form-group">
              <input type="checkbox" id="rememberMe" v-model="rememberMe" style="display:inline-block;width:20px !important">
              <label class="textzc bottom" style="display:inline;">注册账户即代表您同意本平台<a href="#"   @click="openDialog" class="switch">《注册协议》</a></label>
        </div>
        <button type="submit" @click="register()" style="height:50px;font-size:18px;font-weight: 500;" class="regButton">注册</button>
      </form>


    </div>

    <div>
      <div v-if="showDialog" class="dialog-wrapper">
        <div class="dialog">
          <h2 class="dialog-title">注册协议</h2>

          <div class="dialog-content">
          <p  class="contCenter">
            第一章  总则
          </p>
            <p  class="contP">
           第一条  为更好地发挥甲、乙双方各自优势，实现资源共享、合作共赢，加速科技成果与泰安当地产业精准对接，促进科技成果加速转化，为地方经济社会发展提供有力支撑，共同推动建设山东省技术成果交易中心泰安分中心。在平等自愿、诚实守信的基础上，就合作事宜订立本协议，以资共同遵守。
            </p>
          <p  class="contCenter"> 第二章  各方权利与义务
          </p>
            <p class="contP">
            第二条  甲方权利与义务
              <p class="contP">
                一、甲方权利</p>
                <p class="contP">
                  1.支持建设山东省技术成果交易中心泰安分中心，并支持乙方开展泰安市范围内技术产权交易业务，包括但不限于：从事专利、商标、版权、技术、科技型企业股权等技术产权交易及相关配套服务；</p>
              <p class="contP">2.监督乙方代理相关技术产权的交易业务，同意泰山科学技术研究院（泰安市创新发展研究院）承接山东省技术成果交易中心泰安分中心建设；</p>
              <p class="contP">  3.山东省技术成果交易中心泰安分中心应定期向甲方汇报日常业务和具体交易信息等。
              </p>
              <p class="contP"> 二、甲方义务</p>
              <p class="contP">
            1.甲方打造科技体制机制创新“试验田”。坚持政府主推，学习借鉴国内外成功经验，聚焦人才与机制两个关键点，探索推动以“众筹科研”、项目经理负责制、科技成果市场化评价、高校院所科技成果转化“放心转”等系列创新改革举措。落实好国家、省市规定的各项优惠政策，协助乙方或甲乙双方共同指定的其他主体按要求享有有关优惠政策；甲方为乙方或甲乙双方共同指定的其他主体的业务提供配套政策、资金等保证，协助组织好相关项目在泰安市落地实施。
              </p>
              <p class="contP">2.同意乙方在选定地点以山东省技术成果交易中心泰安分中心名义，按乙方相关制度规则开展技术产权交易业务；</p>
              <div class="contP">
            3.协助乙方和其他投资人办理项目的批准文件和工商、税务等登记手续，妥善协调处理山东省技术成果交易中心泰安分中心建设和运营期间的纠纷和其他相关问题，依法保障乙方及相关其他主体合法权益，为山东省技术成果交易中心泰安分中心建设提供良好的投资和服务环境。
              </div>
              <p class="contP">
            第三条  乙方权利与义务
                <p class="contP">
            一、乙方权利
                </p>
                  <p class="contP">
            1.乙方在泰安设立山东省技术成果交易中心泰安分中心，分中心开展交易业务时，应按照乙方业务规则规定的受理转让申请、发布挂牌信息公告、登记受让意向、组织交易签约、结算交易资金、出具产权交易凭证的程序进行，并按照统一交易系统、统一信息发布、统一技术标准和数据规范、统一业务监管、统一综合配套服务、统一结算平台”的“六统一”开展业务；
                  </p>
                    <p class="contP">
            2.分中心通过乙方设立的产权交易价款结算专用账户进行交易资金结算，交易相关费用参照《企业国有资产交易服务收费标准及有关说明》（鲁发改成本〔2019〕1049号）及山东省技术成果交易中心收费标准收取，结算流程按照乙方相关工作流程执行，交易价款及交易费用入账后，由乙方出具《产权交易凭证》；
                    </p>
                      <p class="contP">
                      3.乙方同意授权泰山科学技术研究院（泰安市创新发展研究院）运营山东省技术成果交易中心泰安分中心，并为其提供技术、政策咨询及业务指导。
                </p>
                  <p class="contP">
            二、乙方义务
                  </p>
                    <p class="contP">
            1.严格遵守国家法律法规及交易业务的有关规定，确保交易业务公开、公平、公正进场交易；
                    </p>
                <p class="contP">
            2.严格遵守甲方相关制度规定，切实规范履行甲方赋予的业务职能；
                </p>
                <p class="contP">
            3.接受甲方和相应业务主管机构的监督、指导。
                  </p>
          </p>
          <p  class="contCenter">
            第三章  其他条款
          </p>
            <p class="contP">
            第四条  合作期限
            本协议有效期3年，自2024年 月 日至2027年 月 日期满，各方在合同期满前未提出书面异议的，本协议可延长2年，须在本协议期满前三个月另行签订书面协议，书面协议内容可根据山东省技术成果交易中心泰安分中心实际业务发展需要确定，未另行签订的，本协议自动终止。
            </p>
            <p class="contP">
            第五条  保密
            </p>
              <p class="contP">
            1.协议双方均应遵循诚实守信原则，甲乙双方及其代表提供给对方的所有文件（包括：文件、技术数据、商业秘密、专有技术、研究、产品计划、服务、客户、市场、软件、营销计划、财务状况等）的方式直接或间接向另一方披露的任何信息，或者根据其性质或具体内容，被普遍认为是保密信息和/或一方专有信息。）都属机密信息，双方均应妥善保管，不得将上述商业机密和保密信息用于其他目的；任何一方不得未经对方书面同意单方面向第三方提供上述机密信息，任何一方泄密造成损失的，均应承担相关的法律责任，并进行赔偿。
              </p>
                <p class="contP">
                  2.未经对方事先书面同意，不得以任何方式向本协议以外第三方披露相关信息，但法律、法规另有规定和国资监管部门另有要求的除外。
                </p>
            </p>
              <p class="contP">
            第六条  协议的解除和终止
                <p class="contP">
            1、乙方有下列情形的，甲方有权单方解除本协议，甲方单方解除本协议的，自书面解除通知到达乙方之日起协议解除：
                </p>
                  <p class="contP">
            (1) 乙方违反相关法律法规等规定，导致交易业务违反公平、公开、公正原则的；
                  </p>
                    <p class="contP">
            (2) 乙方违反甲方相关制度规定造成不良后果的；
                    </p>
                      <p class="contP">
            (3) 乙方拒绝接受甲方及相关业务主管机构的监督和指导并拒绝改正的；
                      </p>
                        <p class="contP">
            (4) 其他严重违反本协议约定的权利义务的。
                        </p>
                <p class="contP">
            2、协议期限内，有下列情形的，双方应协议解除：
                </p>
                <p class="contP">
            (1)	因不可抗力致使不能实现协议目的的；
                </p>
                <p class="contP">
            (2)	双方协商一致解除本协议的。
                </p>
                <p class="contP">
            3、协议解除后，乙方依法妥善处理未完成的交易并解散和注销山东省
                </p>
          </p>
          </div>
          <button @click="closeDialog" class="dialog-button">关闭</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getCodeImg, register} from "@/api/login";
import {uploadImg} from "@/api/http";

export default {
  data() {
    return {
      //1为个体2为企业
      radio: 0,
      individualShow:false,
      showDialog:false,
      openWid:true,
      imagePreview: null, // 用于存储图片预览的URL
      phone: '',
      password: '',
      captcha: '',
      rememberMe: false,
      isCaptchaLogin: false, // 切换到手机验证码登录的状态
      codeUrl:'',
      passwordTwo:"",
      uuid:this.uuid,
      companyName:''
    };
  },
  created() {
    this.getCode();
  },


  methods: {

    showPageHome() {
      this.$router.push({name: 'home'});
    },

    changeRadio(item){
      console.log("item",item);
      this.individualShow = item === '1';
      console.log("individualShow",this.individualShow);
    },
    openPDF(){

      try {
        // const response =  fetch('http://192.168.31.182:10443/upload/upload//20241105/0e305c5f7098494f8eb059c35cb23d2d.pdf');
        // const blob =  response.blob();
        // const blobURL = URL.createObjectURL(blob);
        window.open('http://192.168.31.182:10443/upload/upload//20241105/0e305c5f7098494f8eb059c35cb23d2d.pdf', '_blank');
      } catch (error) {
        console.error('无法加载 PDF 文件:', error);
      }

    },

    openDialog(){
      this.showDialog = true;
    },
    closeDialog(){
      this.showDialog= false;
    },
    captchaNew()
{
  this.getCode();
},
    register(){
      const loginData = {
        username: this.phone,
        password: this.password,
        captcha: this.captcha,
        companyName:this.companyName,
        businessLicense:this.imgUrl,
        code:this.captcha,
        uuid:this.uuid,
        individual:this.radio
      };

      register(loginData).then(res=>{
        if (res.code === 200){
          console.log("成功")
          this.$router.push({ name: 'Login' });
        }
      })
    },

    getCode() {
      getCodeImg().then(res => {
        this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          // this.loginForm.uuid = res.uuid;
          this.uuid  = res.uuid;
        }
      });
    },


    handleFileChange(event) {
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0];  // 获取单个文件
        // 生成预览URL
        this.imagePreview = URL.createObjectURL(file);

        // 调用上传接口
        this.uploadFile(file);
      } else {
        this.imagePreview = null;
      }
    },

    async uploadFile(file) {
      const response = await uploadImg(file).then(res=>{
        if (res.code === 200){
          console.log('上传成功:', res);
          console.log(res.data.newName);
          this.imgUrl =res.data.newName

        }else {
          console.error('上传失败:',res);
        }
      });




    },

    handleLogin() {
      // 在这里处理登录逻辑
      console.log('登录信息:', {
        phone: this.phone,
        password: this.password,
        captcha: this.captcha,
        rememberMe: this.rememberMe
      });
      // 可以调用API进行登录
    },
    switchToRegister() {
      // 切换到注册账号的逻辑
      console.log('切换到注册');
      // 你可以在这里跳转到注册页面
    },
    switchToCaptchaLogin() {
      // 切换到手机验证码登录的逻辑
      this.isCaptchaLogin = true;
      console.log('切换到手机验证码登录');
      // 你可以在这里修改表单以显示验证码登录相关的输入项
    },
    forgetPassword() {
      // 忘记密码，找回密码的逻辑
      console.log('找回密码');
      // 你可以在这里跳转到找回密码页面
    }
  }
};
</script>

<style>
.login-page {
  position: fixed;  /* 使用fixed定位占满整个视口 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.layout{
  background-color:transparent !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #e5eeff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form {
  box-sizing: border-box;
  background-color:#FFFFFF;
  width: 40%;
  padding:30px 45px 30px;
  margin: 5% auto;
  border-radius: .67rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.loginlogo{

  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}
.textstyle{
  font-size: 3rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  margin-bottom: 4.08rem;
}
.textzc{
  display: flex;
  align-items: center;
  font-size: 1.08rem;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #333;
  margin-bottom: 1.92rem;
}
.bottom{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  font-size: 1.08rem;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #333;
}
.switch{
  color: #2782ff;
  text-decoration: underline;
  cursor: pointer;
}
input{
  box-sizing: border-box;
  background: #fff;
  width: 100%;
  padding: 1rem 1.25rem .92rem;
  border: 1px solid #aeaeae;
}
.dialog-button:hover{
  background-color: #2238c2 !important;
}
.dialog-button{
  background-color: #2238c2 !important;
}
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="password"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.dialog-button {
  width: 100%;
  padding: 10px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}



p {
  //text-align: center;
}
.photo-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-image {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}
.hidden-file-input {
  display: none;
}

.custom-file-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #409eff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  width:22%;

}

.custom-file-label:hover {
  background-color: #66b1ff;
}


.dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.dialog {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  width: 80%;
}

.dialog-title {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.dialog-content {
  margin-bottom: 20px;
  height: 400px;
  overflow-y: auto;
}

.dialog-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.dialog-button:hover {
  background-color: #0056b3;
}
.contP{
  margin-bottom: 10px;
}
.contCenter{
  text-align: center;
  margin-bottom: 10px;
}
p {
  text-align: left !important;

}
.regButton{
  width: 100%;
  padding: 10px;
  background-color: #2238c2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}
</style>


